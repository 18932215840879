<template>
  <form @submit.prevent="setPass" class="entry-form__list">
    <div class="entry-form__item">
      <input
        v-model.trim.lazy="$v.email.$model"
        :class="{
          error: $v.email.$error || sendError,
          valid: email && !$v.email.$error,
        }"
        class="input-entry"
        placeholder="E-mail"
        required
        type="email"
      />
      <div v-if="$v.email.$error" class="error-list__item">
        {{ $t("required_field") }}
      </div>
    </div>
    <vue-recaptcha
      class="pt-2 pb-4 d-flex justify-content-center w-100"
      sitekey="6LdWqHkUAAAAABxWRXeYYXWW6F2F3LswjImcYoWW"
      size="normal"
      theme="light"
      tabindex="0"
      :loadRecaptchaScript="true"
      @verify="recaptchaVerified"
      @expire="recaptchaExpired"
      @fail="recaptchaFailed"
      ref="vueRecaptcha"
    />
    <div class="entry-form__item _submit">
      <button class="btn _w100" type="submit" :disabled="newPassSent">
        {{ $t("auth.send_new_pass") }}
      </button>
    </div>
    <div class="entry-form__item _privacy mb-0">
      <router-link to="/login">{{ $t("platforms.cancel") }}</router-link>
    </div>
    <alert v-if="$alert.title" />
  </form>
</template>

<script>
import { validationMixin } from "vuelidate";
import { VueRecaptcha } from "vue-recaptcha";
import { email, required } from "vuelidate/lib/validators";
import AuthService from "@/api/serviceAuth";

export default {
  name: "ResetPass",
  mixins: [validationMixin],
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      email: "",
      recaptcha_response: "",
      sendError: false,
      newPassSent: false,
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    recaptchaVerified(response) {
      this.recaptcha_response = response;
    },
    recaptchaExpired() {
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {
      this.$refs.vueRecaptcha.reset();
    },
    async setPass() {
      this.$v.$touch();
      this.sendError = false;

      if (this.$v.$invalid) {
        this.$alert({
          title: this.$t("sent_error"),
          text: this.$t("fields_invalid"),
        });
        return;
      }

      if (!this.recaptcha_response) {
        this.$alert({
          title: this.$t("sent_error"),
          text: this.$t("toast_error_captcha"),
        });
        return;
      }
      const params = {
        email: this.email,
        recaptcha_response: this.recaptcha_response,
      };
      this.newPassSent = true;
      AuthService.restorePassword(params).then((resp) => {
        if (resp.type === "Error" || resp.status === 400) {
          this.$alert({
            title: this.$t("sent_error"),
            text: resp.data.message,
          });
          this.sendError = true;
          this.recaptchaFailed();
          this.newPassSent = false;
        } else {
          this.$alert({
            title: this.$t("sent_success"),
            text: this.$t("auth.send_link"),
            type: "success",
          });
          this.newPassSent = false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.switch-password .btn.btn-empty {
  padding: 0;
}
</style>